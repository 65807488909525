import React, { Component } from "react";

class WelcomePage extends Component {
    render() {
        return (
            <div className="container">
                <h1>Welcome, ;)</h1>
                <p className="lead">Well done is better than well said.</p>
            </div>
        );
    }
}

export default WelcomePage;
