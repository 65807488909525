import React, { Component } from "react";

class NotFound extends Component {
    render() {
        return (
            <div className="container">
                <h1>404</h1>
                <p>the page is not found! ;(</p>
            </div>
        );
    }
}

export default NotFound;
